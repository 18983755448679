import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function Mode() {
  const itemData = [
{"asin": "B0CW66PPN4", "Title": "YouShow Lot de 3 Shorty Femme sous Jupe Douce Boxer Short S\u00e9curit\u00e9 Legging Court sans Couture", "MainImage": "https://m.media-amazon.com/images/I/31RnzWCIscL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "5 \u00e9valuations", "Price": "", "AvailableSizes": "XS, S, M, L, XL, XXL", "AvailableColors": "1 Noire + 2 Beiges, 3 Blancs, Beige X 3, Noir X 3, Noir, Beige et Blanc", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D46VZYPD", "Title": "G\u00e9n\u00e9rique Soutien-Gorge Pigeonnant pour Femmes, Deep-V Plunge Soutien-Gorge Dos Nu r\u00e9glable avec Bretelles Transparentes, Soutien-Gorge Convertible pour Grandes Tailles", "MainImage": "https://m.media-amazon.com/images/I/418D+pzdL1L._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL", "AvailableColors": "01 Beige, 01 Blanc, 01 Noir, 02 Beige, 02 Blanc, 02 Kaki, 02 Noir, 03 Beiges, 03 Blanc, 03 Noir, 04 Beige, 04 Kaffee, 04 Schwarz, 04 Wei\u00df", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2GVRLY7", "Title": "Tuopuda Culotte Femme Gainante Ventre Plat String Sculptantes Sexy Chic Tanga Gaine Amincissante Minceur Invisible Body Shaper Slip Dentelle Shapewear Thong", "MainImage": "https://m.media-amazon.com/images/I/3181bVqQ9pL._AC_.jpg", "Rating": "3,1 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "5 \u00e9valuations", "Price": "", "AvailableSizes": "XS, S, M, L, XL", "AvailableColors": "Beige, Noir, Noir + Beige", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2HY49T4", "Title": "TIICHOO Culotte Menstruelle Tres Abondant Absorbante Lavable pour Les Adolescents Femme Culotte de Regle", "MainImage": "https://m.media-amazon.com/images/I/31hU53gkfNL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "25 \u00e9valuations", "Price": "", "AvailableSizes": "XS, S, M, L, XL, XXL, 3XL", "AvailableColors": "Brun, Noir", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D4DR42F8", "Title": "Robe Femme Col en V \u00e0 manches courtes d\u00e9contract\u00e9 avec Boh\u00e8Me Imprim\u00e9S Pour \u00c9t\u00e9 Chic et Elegant d\u00e9bardeur Midi sans MancheLongueur Genou Taille Haute De Plage A-Line \u00c9l\u00e9gante R\u00e9tro Robe \u00c9t\u00e9 2024", "MainImage": "https://m.media-amazon.com/images/I/51C6R7iqUKL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "A, B, C, D, E, F, G, H, I, J, K, L, M, N", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D4R1ZL1P", "Title": "Chanpning Short d'\u00e9t\u00e9 d\u00e9contract\u00e9 en Coton et Lin avec Cordon de Serrage \u00e0 la Taille et Poches pour Femme - Short de randonn\u00e9e - Longueur Genou - V\u00eatement de d\u00e9tente Taille Haute - Pantacourt", "MainImage": "https://m.media-amazon.com/images/I/31ACQH79t7L._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "Ag, Beige, Blanc, Dark Blue, Dark Gray, Gris, Kaki, Mauve, Navy, Noir, Sky Blue, Wine, Z01 Blue, Z01 Coffee, Z01 Orange, Z01 Purple, Z01 Yellow", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D5HF3Q1R", "Title": "JOPHY & CO. Short do femme large effet jupe pantalon court 100 % coton pour printemps, \u00e9t\u00e9 cod. 9487", "MainImage": "https://m.media-amazon.com/images/I/31jCWYu2fDL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "Beige Dentelle, Blanc Dentelle, Bleu Fonc\u00e9 Dentelle, Camel, Camel Dentelle, Dentelle Militaire, Gris Moyen Dentelle, Jean Dentelle, Militaire, Noir, Noir Dentelle, Rose Antique Dentelle, Rouge Dentelle, Vert Dentelle, Blanc", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D4QRQHGN", "Title": "tiorhooe Paris Tee Shirt Femme France Souvenir T-Shirt Tour Eiffel Paris Fille Tshirt", "MainImage": "https://m.media-amazon.com/images/I/41UOTasTXbL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "Blanc1, Noir1, Vert1", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2QGV8SV", "Title": "heekpek Pantacourt Femme \u00e9t\u00e9 Pantalon Cargo 3/4 Pantalon Capri D\u00e9contract\u00e9 Pantalon d'ext\u00e9rieur Pantalon Corsaire Femme avec 6 Poches Pantalon ete Femme Leger pour la Randonn\u00e9e", "MainImage": "https://m.media-amazon.com/images/I/31GKQ-O-DSL._AC_.jpg", "Rating": "4,3 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "25 \u00e9valuations", "Price": "", "AvailableSizes": "M, L, XL, XXL", "AvailableColors": "Bleu Marine, Gris Fonc\u00e9, Kaki, Noir, Vert Arm\u00e9e", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2XTXK54", "Title": "GRMLRPT Robe Femme d'\u00c9t\u00e9 sans Manches Boho Mi-Longues Style Ethnique \u00c9l\u00e9gant Imprim\u00e9 Mesh R\u00e9tro Robe Neckholder Loisirs Femme Robes de Plage Femme(Multicolore-1,S-XXL)", "MainImage": "https://m.media-amazon.com/images/I/41Q-JAMcliL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "Bleu, Bleu-2, Multicolore, Noir-1, Noir-2, Noir-3, Rose", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D3BZ1B4R", "Title": "Merclix 12 Paires Chaussettes Femme 39 42 Coton Socquette Femme 39 42 Chaussettes Basses Femme Chaussettes Rigolotes Femme Sport Chaussette Humour Femme", "MainImage": "https://m.media-amazon.com/images/I/51Q66ZlJlhL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D4Y3N68W", "Title": "Robe Longue Femme en Satin \u00e9l\u00e9gante Sexy Dos Nu Fendue Spaghetti Sangle Couleur Unie Swing pour Bal Mariage Cocktail Robe de Soir\u00e9e Longue Mariage Party Dress", "MainImage": "https://m.media-amazon.com/images/I/51uroq7HoNL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "01 Blanc, 01 Bleu Ciel, 01 Bleu Fonc\u00e9, 01 Gris, 01 Kaki, 01 Menthe Verte, 01 Noir, 01 Rose Vif, 01 Rouge, 01 Vert, 01 Vin, 01 Violet, 02 Bleu, 02 C, 02 Marine, 02 Vert, 02 Violet", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2Y16XM8", "Title": "KSJEHW Mesh Kristall Top Festival Outfit Strass D\u00e9bardeur creus\u00e9 scintillant Accessoire corps pour femmes et filles", "MainImage": "https://m.media-amazon.com/images/I/41ihABPUA6L._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2Y4M59Y", "Title": "Cihdmfb Hauts d'\u00e9t\u00e9 pour Femmes, 2024, oeillet, \u00e9paules d\u00e9nud\u00e9es, Manches Courtes, t-Shirts, Couleur Unie, Anneau d\u00e9contract\u00e9, Haut Chemisiers De Travail pour Femmes Bureau Manches Longues", "MainImage": "https://m.media-amazon.com/images/I/4106S1BzjZL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "Ag, Kaki, Le Noir, Marine, Menthe Verte, Rose, Vert, Violet, Yaa-ag, Yaa-blanc, Yaa-bleu, Yaa-bleu Ciel, Yaa-bleu Clair, Yaa-le Noir, Yaa-marine, Yaa-rose, Yaa-rose Vif, Yaa-vert, Yaa-violet", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D6B8M67W", "Title": "HAOLEI Chemise D'\u00e9T\u00e9 Femme Coton Et Lin T-Shirt D'\u00e9T\u00e9 Ample Manches Courtes \u00e0 Col en V Tunique Blouse Grande Taille Pas Cher Hauts Tops Mode Et Confortable Imprim\u00e9 Chemisier", "MainImage": "https://m.media-amazon.com/images/I/41XvRHE0HLL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "Blanc, Bleu, Kaki, Noir, Rose", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2KJ2S4Q", "Title": "Pelinuar Soutien-gorge push-up sans fil anti-affaissement, soutien-gorge push-up sans armatures, poitrine respirante fronc\u00e9e sans couture, lingerie anti-affaissement, large bretelle, sommeil", "MainImage": "https://m.media-amazon.com/images/I/41Fup4QKymL._AC_.jpg", "Rating": "4,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "M, L, XL, XXL", "AvailableColors": "Blanc, Bleu, Noir, Vert", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D4Z2BWGL", "Title": "G\u00e9n\u00e9rique Bandeau sans Couture Soutien-Gorge sans Bretelles Top Tube Extensible avec Coussinets Amovibles pour Femmes", "MainImage": "https://m.media-amazon.com/images/I/3118cyJ-BlL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "01 Beige, 01 Blanc, 01 Noir, 02 Beige, 02 Blanc, 02 Gris, 02 Kaki, 02 Noir", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D6Z9WSSV", "Title": "Pantalon Fluide Femme \u00c9t\u00e9 Taille Elastique Pantalon Ample Pantalon Droit Fluide Taille Haute Pantalon Lin Noir Sport Pantalon Leger Ete Pantalon Large Pantalon en Lin", "MainImage": "https://m.media-amazon.com/images/I/31qE-vhK6ZL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "01 Beige, 01 Black, 01 Dark Blue, 01 Grey, 01 Purple, 01 White", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CWFQD1GK", "Title": "Ormromra Ensemble de jambi\u00e8res roses pour femme des ann\u00e9es 80 - Jambi\u00e8res n\u00e9on - Ensemble d'accessoires de carnaval - Ensemble d'accessoires et anneau de cheveux - Bas chauffe-jambes - Bracelets pour", "MainImage": "https://m.media-amazon.com/images/I/51k3J13NxeL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D53WQVL5", "Title": "T Shirt D'\u00e9T\u00e9 \u00e0 Manches Courtes pour Gar\u00e7Ons Et Filles Tshirt Jo 2024 V\u00eaTements Parents R\u00e9union Sportive Sport Tee Shirt Enfants ColRond D\u00e9Contract\u00e9 Et Ample Hauts Imprim\u00e9S D\u00e9Contract\u00e9S Hauts Imprim\u00e9S", "MainImage": "https://m.media-amazon.com/images/I/41y9yuACJiL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "2-3 ans, 4-5 ans, 6-7 ans, 8-9 ans, 10-11 ans, 11-12 ans, S, M, L, XL, XXL, 3XL", "AvailableColors": "B-bleu Clair-1, B-bleu-1, B-jaune-1, B-past\u00e8que Rouge-1, B-rose Vif-1, B-rose-1, B-rouge-1, B-vert-1, B-violet-1, C-blanc-1, C-bleu Ciel-1, C-gris Fonc\u00e9-1, C-jaune-1, C-le Noir-1, C-marine-1, C-rose-1, C-vert-1, C-violet-1, A-ag-1, A-bleu Ciel-1, A-bleu-1, A-menthe Verte-1, A-past\u00e8que Rouge-1, A-rose-1, D-ag-1, D-bleu Ciel-1, D-bleu-1, D-menthe Verte-1, D-past\u00e8que Rouge-1, D-rose-1, E-blanc-1, E-bleu Ciel-1, E-gris Fonc\u00e9-1, E-le Noir-1, E-marine-1, E-vert-1, F-blanc-1, F-bleu Ciel-1, F-gris Fonc\u00e9-1, F-le Noir-1, F-rose-1, F-violet-1", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D3HZTD26", "Title": "Pantalon de Sport Femme Pantalon L\u00e9ger Taille Haute Capri Pantalon Elastique Et\u00e9 Randonn\u00e9e 3/4 Pants Pantalon \u00c9t\u00e9 Coton en Lin Harem Pantalon de Randonn\u00e9e D\u00e9contract\u00e9 avec Poches Pas Cher", "MainImage": "https://m.media-amazon.com/images/I/31EzGVPVfkL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "A1-arm\u00e9e Verte, A10-bleu Ciel, A11-le Noir, A12-bleu, A13-brun, A14-vert, A15-gris, A16-kaki, A17-rouge, A18-blanc, A19-bleu Fonc\u00e9, A2-beige, A20-arm\u00e9e Verte, A21-brun, A22-vert, A23-gris, A24-kaki, A25-bleu Clair, A26-rose, A27-arm\u00e9e Verte, A28-bleu, A29-bleu Clair, A29-vert, A3-gris Fonc\u00e9, A30-menthe Verte, A31-orange, A32-bleu Ciel, A33-rose Vif, A34-menthe Verte, A35-marine, A36-orange, A37-rose, A38-violet, A39-jaune, A4-marine, A5-vin, A6-caf\u00e9, A7-gris, A8-menthe Verte, A9-violet", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D5NQTWFT", "Title": "Stycizom Soutien Gorge Invisible Adh\u00e9sifs 2 Paires Auto-adh\u00e9sifs sans Bretelles R\u00e9utilisable Invisible Soutien-Gorge Respirant Push-up sans Bretelles", "MainImage": "https://m.media-amazon.com/images/I/317rSHurbPL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Beige+black", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D476J8DT", "Title": "2024 Femmes Bustier Bandeau Tops Sexy \u00c9t\u00e9 Tunique sans Manches Vest T-Shirt Floral Debardeur Ample Chemise sans Bretelle Haut Dos Nu Blouse Couleurs Pures Haut Tendance Doux et Confortable", "MainImage": "https://m.media-amazon.com/images/I/41Q2dWAxk1L._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "S, M, L, XL, XXL", "AvailableColors": "Blanc, Couleur Kaki X01, Jaune, Le Pink, Le Vin X01, Le X01 - Ag, Mauve, Noir, Vert, X01 Beige, X01 Bleu, X01 Bleu Ciel, X01 Bleu Clair, X01 Bleu Fonc\u00e9, X01 Bronze, X01 Brun, X01 Camouflage, X01 Jaune, X01 Marine, X01 Or Rose, X01 Rose, X01 Rouge, X01 Vert, X01 Vert Menthe, X01 Violet", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D44XMQMB", "Title": "Camisole Femme d\u00e9bardeur avec Soutien-Gorge int\u00e9gr\u00e9 D\u00e9bardeur de en Yoga Gilet rembourr\u00e9 \u00e0 Bretelles Ajustables D\u00e9bardeurs pour Femmes Soutien-Gorge", "MainImage": "https://m.media-amazon.com/images/I/41rOjAUINRL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "S, M, L, XL, XXL, 3XL", "AvailableColors": "A01 Noir, A02 Blanc, A02 Gris, A02 Rose, A03 Beige, A03 Bleu, A03 Rouge, A03 Violet, A04 Rose, A04 Rose Vif, A04 Vert, A04 Violet Clair, A05 Jaune, A05 Or, C01 Blanc, C01 Brun, C01 Noir, C01 Vert, C02 Beige, C02 Bleu, C02 Gris, C02 Jaune, C02 Kaki", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D2MT89LJ", "Title": "shownicer Femme Pantalon \u00c9t\u00e9 Coton en Lin Harem Pantalon L\u00e9ger Taille Haute Capri de Solide Couleur Pantacourt Sport Pants 3/4 Longueur Pantalon de Plage avec Poches", "MainImage": "https://m.media-amazon.com/images/I/31erJzDd+5L._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "XS, S, M, L, XL, XXL, 3XL", "AvailableColors": "A Bleu Clair, A Gris Clair, A Gris Fonc\u00e9, A Kaki, A Noir, A Vert", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D45RXZB5", "Title": "G\u00e9n\u00e9rique Panty Femme Anti Frottement Short sous Robe Doux Shorts de s\u00e9curit\u00e9 Legging sous Jupe Culottes Cycliste Boxer Yoga Shorty Cale\u00e7on sous-V\u00eatements", "MainImage": "https://m.media-amazon.com/images/I/31D3hWJczkL._AC_.jpg", "Rating": "", "NumberOfReviews": "", "Price": "", "AvailableSizes": "M, L, XL, XXL", "AvailableColors": "2pc Beige+beige, 2pc Beige+blanc, 2pc Beige+noir, 2pc Blanc+noir, Beige, Blanc, Noir", "BulletPoints": "", "SellerRank": ""}
];
  return (
    <Box id="mode" xl={{ overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <a href={`https://www.amazon.fr/dp/${item.asin}?tag=wwwlepascherc-21&language=fr_FR/`} target="_blank">
          <ImageListItem key={item.MainImage}>
            <img
              srcSet={`${item.MainImage}?fit=crop&auto=format&dpr=2 2x`}
              src={`${item.MainImage}?fit=crop&auto=format`}
              alt={item.Title}
              loading="lazy"
            />
            <ImageListItemBar
            title={item.Title}
            subtitle={item.Rating}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 5)' }}
                aria-label={`info about ${item.Title}`}
              >
                <InfoIcon />
              </IconButton>
            }
            
          />
            
          </ImageListItem>
          </a>
        ))}
      </ImageList>
    </Box>
  );
}

