import * as React from 'react';

import {useState} from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const onSubmitClick = (e) => {
      e.preventDefault()
      setLoading(true);
      console.log("You pressed register");
      let opts = {
        
        email: email,
        
      };
      console.log(opts);
      fetch("/api/subscribe", {
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.message) {
            setMessage(data.message);
            //console.log(data.message);
          } else {
            console.log("Please type in correct E-mail");
          }
        });
    };
  const handleEmailChange = (e) => {
      setEmail(e.target.value);
      setLoading(false);
    };


  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Luxe&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Pas Cher
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Produits haut de gamme à prix abordable : Mode, Montres, Bijoux, Sacs,  Chaussures.
          </Typography>
          
            
        </Stack>
        
      </Container>
    </Box>
  );
}
