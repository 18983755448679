import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function Bijoux() {
  const itemData = [
{"asin": "B0CSSHBBRB", "Title": "EmDryze Cadeau pour Femme Saint Valentin Cadeau Femme Original Cadeau Ado Fille Cadeaux Noel Femme Cadeau Anniversaire Bracelet Cadeau pour Mamie Maman S\u0153ur Amie", "MainImage": "https://m.media-amazon.com/images/I/41etojAwUDL._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "44 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Beu fonc\u00e9, Bleu, Rose, Vert fonc\u00e9", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CX5VK4VD", "Title": "Swarovski Collection Symbolic", "MainImage": "https://m.media-amazon.com/images/I/41milzHx8CL._AC_.jpg", "Rating": "4,6 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "8\u00a0985 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D18Q56GH", "Title": "TILOVE - Bracelet en Pierre Naturelle Quartz Rose Lithoth\u00e9rapie pour Femme avec Tr\u00e8fle Porte Bonheur en Acier Inoxydable. Con\u00e7u en France. Bracelet Chakra Bien \u00catre. Id\u00e9e Cadeau Femme, Fete des M\u00e8res", "MainImage": "https://m.media-amazon.com/images/I/41-LRnCF8FL._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "9 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CP49W2V4", "Title": "Lacoste Bracelet en cha\u00eene pour Femme Collection CROCODILE disponible en version argent\u00e9e, dor\u00e9e ou or rose clair", "MainImage": "https://m.media-amazon.com/images/I/41t+-8B9ClL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "51 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Carnation Gold, Silver, Yellow Gold", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CZPF7CFN", "Title": "Pandora Love You Mum Infinity Heart Charms", "MainImage": "https://m.media-amazon.com/images/I/31pxatKehKS._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "4\u00a0570 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Sans couleur, argent\u00e9", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CRKJJ892", "Title": "TOFBS Ensemble de Bijoux Tr\u00e8fle \u00e0 Quatre Feuilles pour Femme Fille 18K Plaqu\u00e9 Or Rose Acier Inoxydable Double Face Blanc Noir Tr\u00e8fle \u00e0 Quatre Feuilles Collier Boucles d'oreilles Bracelet Set", "MainImage": "https://m.media-amazon.com/images/I/41Hfi4oyqCL._AC_.jpg", "Rating": "3,7 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "832 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Blanc, Noir, Rouge, Vert", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CZLKTPY7", "Title": "Merclix Idee Cadeau Maitresse Bracelet Maitresse d'\u00e9cole Cadeau Maitresse d'\u00e9cole Fin d'ann\u00e9e Merci Maitresse Cadeau Cadeau Maitresse Noel Cadeaux Maitresse d'\u00e9cole Cadeau Professeur Retraite", "MainImage": "https://m.media-amazon.com/images/I/51F2JhDzZiL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CZL47MNN", "Title": "Pandora Moments Animal Collection", "MainImage": "https://m.media-amazon.com/images/I/31m-Nx2T4pL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1\u00a0817 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B08L84GYT5", "Title": "MAMIJUX Appelle anges maman id\u00e9e cadeau grossesse collier femme rappel des anges cha\u00eene acier, Bois", "MainImage": "https://m.media-amazon.com/images/I/31h2WukqPmS._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "273 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0C5DFNTBB", "Title": "Temulnee Future Maman Cadeau Grossesse Collier Cadeaux pour Future Mere Enceinte Femme Colliers Id\u00e9e M\u00e8re Original Idee Anniversaire Bijoux Noel", "MainImage": "https://m.media-amazon.com/images/I/51+NUxRtLIL._AC_.jpg", "Rating": "4,8 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "5 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B07VVPNRD9", "Title": "CDE Collier pendentif coeur femme en or rose, orn\u00e9 de cristaux bijoux cadeau pour femme m\u00e8re anniversaire/Cadeau de No\u00ebl/Cadeau Saint Valentin", "MainImage": "https://m.media-amazon.com/images/I/51G6fyyUfLL._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2\u00a0301 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Argent/Pearl, Argent/Pourpre, Argent/Rose, Argent/blue, Argent/vert, Or / violet, Or rose / Bleu, Or/Rose, Or/Vert", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CHWBW4D8", "Title": "Collier Stitch, Collie Lilo et Stitch, Collier Stitch Fille, 2Pcs Bijoux Stitch Fille Cadeau Stitch Collier D'amiti\u00e9 Stitch Collier Enfant Fille, PQKL-party Cadeau Stitch pour No\u00ebl Anniversaire F\u00eate", "MainImage": "https://m.media-amazon.com/images/I/417qpolsjrL._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "28 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B00YHM4P62", "Title": "Nazareth Store Cristal Rose Clair Chapelet Collier Catholique M\u00e9daille du Sol Sacr\u00e9 & Crucifix Sac De Velours Marque", "MainImage": "https://m.media-amazon.com/images/I/41WhffRVicL._AC_.jpg", "Rating": "4,7 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "414 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "Collier de perles de cristal rose en verre rose avec collier long chapelet de communion classique, Terre Sainte M\u00e9daille de la Vierge Marie avec alliages solides et croix de J\u00e9sus, Collier chapelet reli\u00e9 \u00e0 59 pieuses poup\u00e9es parfaitement con\u00e7ues pour un usage quotidien, Rosaire Longueur 18 \"/ 46 cm taille de croix 1.6\" perles 8 mm. Sac de protection en velours violet avec logo Nazareth Store inclus, Cadeau religieux pour toute occasion, articles chr\u00e9tiens faits \u00e0 la main, anniversaire, confirmation, premi\u00e8re communion, bapt\u00eame, cadeaux de bapt\u00eame et souvenirs de Terre Sainte.", "SellerRank": ""},
{"asin": "B0C2P9255T", "Title": "Pandach Bracelet Pour Femme Pandora charms avec Fermoir C\u0153ur en Argent 925,Bijoux Femme Cadeaux No\u00ebl Femme Anniversaire F\u00eate des M\u00e8res", "MainImage": "https://m.media-amazon.com/images/I/41WBp5Z7vDS._AC_.jpg", "Rating": "4,2 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "329 \u00e9valuations", "Price": "", "AvailableSizes": "6.7in/17cm, 7.1in/18cm, 7.5in/19cm, 7.9in/20cm, 8.0in/20cm, 8.3in/21cm", "AvailableColors": "CMB057, CMB060, CMB085, KJB008, PAB014, PAB015, PAB016, PAB017, PAB018, KJB004, KJB002", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CHFDJCJ7", "Title": "JRZDFXS Cadeau Noel Amie Bracelets pour Amiti\u00e9 Cadeau Meilleure Amie Cadeau Amie Anniversaire Bracelet Amie Bracelet pour Amie Meilleure Amie Cadeau Cadeau Femme Amie Bracelet Femme", "MainImage": "https://m.media-amazon.com/images/I/41RBJwneHGL._AC_.jpg", "Rating": "4,4 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "47 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "1, 2, 3, 4, 5, 6, 7, 8, 9", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CCXZX63L", "Title": "Swarovski Collection Lovely pour femme", "MainImage": "https://m.media-amazon.com/images/I/418w0nS-TNL._AC_.jpg", "Rating": "4,6 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "572 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Blanc (M\u00e9tal Dor\u00e9 Rose), Blanc (M\u00e9tal Rhodi\u00e9)", "BulletPoints": "", "SellerRank": ""},
{"asin": "B09YRK7GVW", "Title": "AEONSLOVE Collier Bola de Grossesse avec M\u00e9lodie Douce Pendentif Arbre de la Vie Grelot Musical pour Femmes Enceinte, 2 Cha\u00eenes", "MainImage": "https://m.media-amazon.com/images/I/41Ea2Iuo41L._AC_.jpg", "Rating": "4,6 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "192 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Argent, Bleu Bleuet, Lavande, Vert de la Mer, Violet \u00c9toil\u00e9", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0D22MHPSX", "Title": "Bracelet Femme Argent Personnalis\u00e9 26 Lettre Initiale Cadeaux Inspirants pour Les Filles Dame Rappel Quotidien Accessoires Positive Affirmation Cadeau pour Meilleur Ami Cadeaux", "MainImage": "https://m.media-amazon.com/images/I/413aa0w2npL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "4 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "A, B, C, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, V, X, Y, Z", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CM159JP3", "Title": "Collier Stitch Fille, Lilo Stitch Collier Stitch Collier \u00e0 Breloques Bijoux Stitch pour Enfants Cadeau pour les Cadeaux D'anniversaire et de No\u00ebl des Filles", "MainImage": "https://m.media-amazon.com/images/I/41M-3mloC7L._AC_.jpg", "Rating": "4,3 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "99 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "Cadeaux Stitch : Le collier Stitch Gift Cute Pendant est le meilleur cadeau pour les fans de Stitch Lilo, parfait pour les anniversaires, No\u00ebl, Halloween et autres vacances., Taille r\u00e9glable : l'anneau du collier cousu a une petite cha\u00eene d'extension avec un design d'anneau r\u00e9glable, vous permettant d'ajuster la taille du collier \u00e0 tout moment., Design de dessin anim\u00e9 Stitch : bijoux Stitch avec de jolis pendentifs Stitch pour ajouter plus d'attrait aux colliers, parfaits comme cadeaux pour les petites filles., Mat\u00e9riau de haute qualit\u00e9 : les cha\u00eenes cadeaux Lilo et point de croix pour filles sont fabriqu\u00e9es dans un mat\u00e9riau de haute qualit\u00e9, avec une bonne brillance, une texture l\u00e9g\u00e8re, pas facile \u00e0 d\u00e9colorer et une surface lisse., Service client : Si la taille du collier brod\u00e9 pour fille ne correspond pas \u00e0 la situation de port, veuillez nous contacter d\u00e8s que possible et nous vous fournirons des services de retour et d'\u00e9change !", "SellerRank": ""},
{"asin": "B08RXS9SDF", "Title": "Bracelet Infini plaqu\u00e9 Argent en Alliage de Zinc cha\u00eene Strass Bracelet pour Homme Femme Bijoux de Mode, One Size, Zinc, One Size, Zinc", "MainImage": "https://m.media-amazon.com/images/I/41JqFVLzmML._AC_.jpg", "Rating": "3,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "498 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CN1BSPCG", "Title": "Merclix Collier Soeur Id\u00e9e Cadeau Soeur Noel Cadeau pour Soeur Anniversaire Soeur de Coeur Idee Cadeau Femme Original Cadeau Noel Femme Cadeau Anniversaire Femme", "MainImage": "https://m.media-amazon.com/images/I/51aQW6JXxyL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "1 \u00e9valuation", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CR4JVZM6", "Title": "TINGN Double Collier Femme Or Collier, 14K Plaqu\u00e9 Or Lettre A-Z Pendentif Or Collier Initial Personnalis\u00e9 Collier Femme Or Lettre Fille Femme Idee Cadeau Anniversaire, F\u00eate des M\u00e8res, Saint Valentin", "MainImage": "https://m.media-amazon.com/images/I/31RSGNnDg2L._AC_.jpg", "Rating": "4,2 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "11 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "E, F, G, I, J, K, L, M, O, P, Q, U, V, Z", "BulletPoints": "", "SellerRank": ""},
{"asin": "B07H7ZQPNX", "Title": "Mecool Collier Pendentif Collier Or Rose Collier Zircon cubique Collier Pendentif Rond Multi-Pierre", "MainImage": "https://m.media-amazon.com/images/I/41+A6ZXiRLL._AC_.jpg", "Rating": "4,3 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "776 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0BYJXVP58", "Title": "Swarovski Infinity Collection Bracelets", "MainImage": "https://m.media-amazon.com/images/I/41iFLBsIsGL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "5\u00a0850 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Blanc", "BulletPoints": "", "SellerRank": ""},
{"asin": "B08HK9JDMP", "Title": "Tusuzik Collier Femme Collier Infini Coeur\uff0cArgent Sterling 925 Collier avec 5A Blanc/Rouge Zircone Cubique\uff0cCadeaux Bijoux pour Elle\uff0cNo\u00ebl Anniversaire Saint Valentin F\u00eate des M\u00e8res", "MainImage": "https://m.media-amazon.com/images/I/41gAVQ0eguL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "132 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Blanc, Bleu, Choker, Or Rose-rouge, Or rose-Blanc, Rose Fleur, Rouge, Type b-Blanc, Vert", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CVVKK8KX", "Title": "Swarovski Collection Emily Bracelets", "MainImage": "https://m.media-amazon.com/images/I/41m5j51QIuL._AC_.jpg", "Rating": "4,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "94 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "Bleu, Rose", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0C2B5NS2T", "Title": "Pandora Bracelet \u00e0 breloques pour femme en argent 925 avec cha\u00eene \u00e0 rivets 592453C00", "MainImage": "https://m.media-amazon.com/images/I/31o1k3i1PZL._AC_.jpg", "Rating": "4,6 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "308 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CX5QWJFC", "Title": "VibraSens Naturopathie Bracelet Pierre de Lune - vraies pierres naturelles de Lithoth\u00e9rapie - Fabriqu\u00e9 en France - perle naturelle pour la fertilit\u00e9 et l'harmonie de la femme", "MainImage": "https://m.media-amazon.com/images/I/41F6F34vzCL._AC_.jpg", "Rating": "5,0 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "2 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0C5CWG5RG", "Title": "Beau Papa Bracelet Cadeau Papy Arriere Grand Pere Bracelets Cadeaux pour Son P\u00e8re P\u00e9p\u00e9 Cadeau Fete Des Peres de Fils Fille A Mon Grand-p\u00e8re Homme F\u00eate Des P\u00e8res Anniversaire Noel Idee Original Bijoux", "MainImage": "https://m.media-amazon.com/images/I/51lzxwkpMXL._AC_.jpg", "Rating": "4,9 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "15 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "", "SellerRank": ""},
{"asin": "B09F6RZB3N", "Title": "925 Argent Sterling Japonais Micro incrustain Cristal d'\u00e9toile \u00e0 Quatre Pointes Boucles d'oreilles Or Femmes Petits Bijoux de Banquet Mignon", "MainImage": "https://m.media-amazon.com/images/I/41cBSrtImWL._AC_.jpg", "Rating": "3,5 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "248 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "", "BulletPoints": "Sans plomb, sans nickel, sans cadmium, respectueux de l'environnement et ne nuit pas \u00e0 la peau., La conception unique montre votre personnalit\u00e9, pour le sp\u00e9cial vous., Il convient non seulement \u00e0 l'usure quotidienne, mais convient \u00e9galement aux mariages, aux soir\u00e9es et danses, ce qui vous rend tr\u00e8s charmant., C'est un cadeau parfait pour les enfants, les amis, les filles, les \u00e9pouses, les m\u00e8res., Si vous n'\u00eates pas satisfait du produit ou du produit est endommag\u00e9, veuillez nous contacter.", "SellerRank": ""},
{"asin": "B0CRYT4LDN", "Title": "Richapex Bracelet pour Femme Or 18K Plaqu\u00e9 Classique Tennis Bracelets fantaisie Infini Or Bracelet pour Femme Fille Cristal Bijoux", "MainImage": "https://m.media-amazon.com/images/I/41D0E1Vm9KL._AC_.jpg", "Rating": "4,7 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "11 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "bleu, rose", "BulletPoints": "", "SellerRank": ""},
{"asin": "B0CGR294DG", "Title": "Dreamtimes Collier arbre de vie pour femmes en acier inoxydable couleur or Zircon clair pendentif rond Cha\u00eenes de cou Arbre de famille Bijoux de mode", "MainImage": "https://m.media-amazon.com/images/I/31JU3mzXaCL._AC_.jpg", "Rating": "4,6 sur 5\u00a0\u00e9toiles", "NumberOfReviews": "81 \u00e9valuations", "Price": "", "AvailableSizes": "", "AvailableColors": "argent, argent-2, or, or-2", "BulletPoints": "", "SellerRank": ""}
];
  return (
    <Box id="bijoux" xl={{ overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <a href={`https://www.amazon.fr/dp/${item.asin}?tag=wwwlepascherc-21&language=fr_FR/`} target="_blank">
          <ImageListItem key={item.MainImage}>
            <img
              srcSet={`${item.MainImage}?fit=crop&auto=format&dpr=2 2x`}
              src={`${item.MainImage}?fit=crop&auto=format`}
              alt={item.Title}
              loading="lazy"
            />
            <ImageListItemBar
            title={item.Title}
            subtitle={item.Rating}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 5)' }}
                aria-label={`info about ${item.Title}`}
              >
                <InfoIcon />
              </IconButton>
            }
            
          />
            
          </ImageListItem>
          </a>
        ))}
      </ImageList>
    </Box>
  );
}

